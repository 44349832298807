import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import sendIcon from "./send.svg";
import backIcon from "./back.svg";
import forwardIcon from "./forward.svg";
import { useNavbarContext } from "./NavbarContext";

function WaitList() {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentStep, totalSteps, goToNextStep, goToPreviousStep } =
    useNavbarContext();

  const [isVisible, setIsVisible] = useState(true);

  const hideWaitlist = () => {
    setIsVisible(false);
  };

  const [formData, setFormData] = useState({
    name: "",
    location: "",
    countryCode: "+1",
    phone: "",
    creativeWork: "",
    sharingFrequency: "",
    teamSize: "",
    mainMessenger: "",
    mainMessengerFeelings: "",
    timestamp: "",
    email: "",
  });

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const handleInputChange = (event) => {
    let name, value;

    // Check if the event has a target property (standard HTML input)
    if (event.target) {
      ({ name, value } = event.target);
    } else {
      // For react-select, the event itself is the selected option
      name = "countryCode"; // The name of the state property you want to update
      value = event ? event.value : "";
    }
    setFormData({ ...formData, [name]: value });

    // Remove the validation message for the field being edited
    if (validationMessages[name]) {
      setValidationMessages({ ...validationMessages, [name]: "" });
    }
    if (name === "phone") {
      setHasAttemptedSubmit(false);
      if (value.trim() === "") {
        // Disable the Continue button if the phone number field is empty
        setIsPhoneNumberValid(false);
      } else if (/^[\d\s()+-]*$/.test(value)) {
        // Allow digits, spaces, brackets, +, and -
        const digitsOnly = value.replace(/\D/g, ""); // Remove non-digit characters
        setIsPhoneNumberValid(
          digitsOnly.length >= 10 && digitsOnly.length <= 11
        );
      } else {
        setIsPhoneNumberValid(false); // Invalid if the pattern does not match
      }
    }
  };

  const isFormValid = () => {
    let missingFields = [];

    if (formData.name.trim() === "") {
      missingFields.push("Question 1");
    }
    if (formData.location.trim() === "") {
      missingFields.push("Question 2");
    }
    if (!isPhoneNumberValid) {
      missingFields.push("Question 3");
    }
    if (formData.email.trim() === "") {
      missingFields.push("Question 4");
    } else {
      // Regex to validate the email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email.trim())) {
        missingFields.push("Question 4 (Invalid Email)");
      }
    }
    if (formData.creativeWork.trim() === "") {
      missingFields.push("Question 5");
    }
    if (formData.sharingFrequency.trim() === "") {
      missingFields.push("Question 6");
    }
    if (formData.teamSize.trim() === "") {
      missingFields.push("Question 7");
    }
    if (!formData.mainMessenger || formData.mainMessenger.trim() === "") {
      missingFields.push("Question 8");
    }
    if (formData.mainMessengerFeelings.trim() === "") {
      missingFields.push("Question 9");
    }

    if (missingFields.length > 0) {
      alert(
        "Please complete the following fields before submitting: " +
          missingFields.join(", ")
      );
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasAttemptedSubmit(true);
  
    hideWaitlist();
  
    if (!isFormValid() || isSubmitting) {
      return;
    }
  
    setIsSubmitting(true); // Disable further submissions
  
    // Set the timestamp before submitting
    const submissionTime = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
    setFormData({ ...formData, timestamp: submissionTime });
  
    try {
      // Post data to API Spreadsheets
      const apiSpreadsheetResponse = await fetch(
        "https://api.apispreadsheets.com/data/lurGnMfHL9s4NtbK/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ data: { ...formData, timestamp: submissionTime } }),
        }
      );
  
      // Prepare data for Loops
      const formBody = new URLSearchParams();
      for (const key in formData) {
        formBody.append(key, formData[key]);
      }
  
      // Post data to Loops
      const loopsResponse = await fetch(
        "https://app.loops.so/api/newsletter-form/clthgzdpx01ocs9ncfitd5mwv",
        {
          method: "POST",
          body: formBody,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
  
      if (apiSpreadsheetResponse.status === 201 && loopsResponse.ok) {
        console.log("Form submitted successfully to both endpoints");
        setValidationMessages({}); // Clear validation messages
        navigate("/success");
      } else {
        console.error("Form submission failed");
        // Handle the error based on your application's needs
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error based on your application's needs
    } finally {
      setIsSubmitting(false); // Re-enable the submit button
    }
  };
  

  const [validationMessages, setValidationMessages] = useState({});

  const handleSendClick = () => {
    // Logic to handle the click event
    console.log("Send button clicked with name:", formData.name);
    goToNextStep();
    // Add any other actions you want to perform on click
  };



 

  return (
    <div
      className={
        isVisible
          ? "page-content fade-element"
          : "page-content fade-element hidden"
      }
    >
      <div className="waitlist-container">
        <div className="progress-frame">
          <div
            className={`navigation-button-box ${
              currentStep === 1 ? "disable-hover" : ""
            }`}
            onClick={currentStep > 1 ? goToPreviousStep : null}
          >
            {currentStep > 1 && <img src={backIcon} alt="Back" />}
          </div>

          <div className="question-progress-text">
            Question {currentStep} of {totalSteps}
          </div>

          <div
            className={`navigation-button-box ${
              currentStep === totalSteps ? "disable-hover" : ""
            }`}
            onClick={currentStep < totalSteps ? goToNextStep : null}
          >
            {currentStep < totalSteps && (
              <img src={forwardIcon} alt="Forward" />
            )}
          </div>
        </div>

        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div>
              <div className="question-label">What's your name?</div>
              <div className="short-text-input">
                <input
                  className="input-field"
                  type="text"
                  name="name"
                  placeholder="What's your full name?"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {formData.name && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.name && (
                <p className="error-message">{validationMessages.name}</p>
              )}
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <div className="question-label">Where are you based?</div>
              <div className="short-text-input">
                <input
                  className="input-field"
                  type="text"
                  name="location"
                  placeholder="What city are you in??"
                  value={formData.location}
                  onChange={handleInputChange}
                  required
                />
                {formData.location && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.location && (
                <p className="error-message">{validationMessages.location}</p>
              )}
            </div>
          )}



          {currentStep === 3 && (
            <div>
              <div className="question-label">What's your number?</div>
              <div className="phone-country-container">
                <div className="country-code-container">
                <select
                    id="Country_Code"
                    name="countryCode"
                    required
                    value={formData.countryCode}
                    onChange={handleInputChange}
                    className="select-country-code"
                  >
                    <option value="+1">🇺🇸 +1</option>
                    <option value="+1CA">🇨🇦 +1</option>
                    <option value="+44">🇬🇧 +44</option>
                    <option value="+61">🇦🇺 +61</option>
                    <option value="+81">🇯🇵 +81</option>
                    <option value="+82">🇰🇷 +82</option>
                    <option value="+65">🇸🇬 +65</option>
                    <option value="+971">🇦🇪 +971</option>
                    <option value="+355">🇦🇱 +355</option>
                    <option value="+376">🇦🇩 +376</option>
                    <option value="+374">🇦🇲 +374</option>
                    <option value="+43">🇦🇹 +43</option>
                    <option value="+994">🇦🇿 +994</option>
                    <option value="+375">🇧🇾 +375</option>
                    <option value="+32">🇧🇪 +32</option>
                    <option value="+387">🇧🇦 +387</option>
                    <option value="+359">🇧🇬 +359</option>
                    <option value="+385">🇭🇷 +385</option>
                    <option value="+357">🇨🇾 +357</option>
                    <option value="+420">🇨🇿 +420</option>
                    <option value="+45">🇩🇰 +45</option>
                    <option value="+372">🇪🇪 +372</option>
                    <option value="+358">🇫🇮 +358</option>
                    <option value="+33">🇫🇷 +33</option>
                    <option value="+995">🇬🇪 +995</option>
                    <option value="+30">🇬🇷 +30</option>
                    <option value="+36">+36</option>
                    <option value="+354">🇮🇸 +354</option>
                    <option value="+353">🇮🇪 +353</option>
                    <option value="+39">🇮🇹 +39</option>
                    <option value="+7">🇰🇿 +7</option>
                    <option value="+371">🇱🇻 +371</option>
                    <option value="+423">🇱🇮 +423</option>
                    <option value="+370">🇱🇹 +370</option>
                    <option value="+352">🇱🇺 +352</option>
                    <option value="+389">🇲🇰 +389</option>
                    <option value="+356">🇲🇹 +356</option>
                    <option value="+373">🇲🇩 +373</option>
                    <option value="+377">🇲🇨 +377</option>
                    <option value="+382">🇲🇪 +382</option>
                    <option value="+31">🇳🇱 +31</option>
                    <option value="+47">🇳🇴 +47</option>
                    <option value="+48">🇵🇱 +48</option>
                    <option value="+351">🇵🇹 +351</option>
                    <option value="+40">🇷🇴 +40</option>
                    <option value="+7">🇷🇺 +7</option>
                    <option value="+381">🇷🇸 +381</option>
                    <option value="+421">🇸🇰 +421</option>
                    <option value="+386">🇸🇮 +386</option>
                    <option value="+34">🇪🇸 +34</option>
                    <option value="+46">🇸🇪 +46</option>
                    <option value="+41">🇨🇭 +41</option>
                    <option value="+90">🇹🇷 +90</option>
                    <option value="+380">🇺🇦 +380</option>
                    <option value="+39">🇻🇦 +39</option>
                    <option value="+54">🇦🇷 +54</option>
                    <option value="+591">🇧🇴 +591</option>
                    <option value="+55">🇧🇷 +55</option>
                    <option value="+56">🇨🇱 +56</option>
                    <option value="+57">🇨🇴 +57</option>
                    <option value="+593">🇪🇨 +593</option>
                    <option value="+592">🇬🇾 +592</option>
                    <option value="+595">🇵🇾 +595</option>
                    <option value="+51">🇵🇪 +51</option>
                    <option value="+597">🇸🇷 +597</option>
                    <option value="+598">🇺🇾 +598</option>
                    <option value="+58">🇻🇪 +58</option>
                    <option value="+93">🇦🇫 +93</option>
                    <option value="+374">🇦🇲 +374</option>
                    <option value="+994">🇦🇿 +994</option>
                    <option value="+973">🇧🇭 +973</option>
                    <option value="+880">🇧🇩 +880</option>
                    <option value="+975">🇧🇹 +975</option>
                    <option value="+673">🇧🇳 +673</option>
                    <option value="+95">🇲🇲 +95</option>
                    <option value="+855">🇰🇭 +855</option>
                    <option value="+86">🇨🇳 +86</option>
                    <option value="+357">🇨🇾 +357</option>
                    <option value="+995">🇬🇪 +995</option>
                    <option value="+91">🇮🇳 +91</option>
                    <option value="+62">🇮🇩 +62</option>
                    <option value="+98">🇮🇷 +98</option>
                    <option value="+964">🇮🇶 +964</option>
                    <option value="+972">🇮🇱 +972</option>
                    <option value="+81">🇯🇵 +81</option>
                    <option value="+962">🇯🇴 +962</option>
                    <option value="+7">🇰🇿 +7</option>
                    <option value="+965">🇰🇼 +965</option>
                    <option value="+996">🇰🇬 +996</option>
                    <option value="+856">🇱🇦 +856</option>
                    <option value="+961">🇱🇧 +961</option>
                    <option value="+60">🇲🇾 +60</option>
                    <option value="+960">🇲🇻 +960</option>
                    <option value="+976">🇲🇳 +976</option>
                    <option value="+977">🇳🇵 +977</option>
                    <option value="+850">🇰🇵 +850</option>
                    <option value="+968">🇴🇲 +968</option>
                    <option value="+92">🇵🇰 +92</option>
                    <option value="+63">🇵🇭 +63</option>
                    <option value="+974">🇶🇦 +974</option>
                    <option value="+966">🇸🇦 +966</option>
                    <option value="+65">🇸🇬 +65</option>
                    <option value="+82">🇰🇷 +82</option>
                    <option value="+94">🇱🇰 +94</option>
                    <option value="+963">🇸🇾 +963</option>
                    <option value="+992">🇹🇯 +992</option>
                    <option value="+66">🇹🇭 +66</option>
                    <option value="+90">🇹🇷 +90</option>
                    <option value="+993">🇹🇲 +993</option>
                    <option value="+971">🇦🇪 +971</option>
                    <option value="+998">🇺🇿 +998</option>
                    <option value="+84">🇻🇳 +84</option>
                    <option value="+967">🇾🇪 +967</option>
                    <option value="+213">🇩🇿 +213</option>
                    <option value="+244">🇦🇴 +244</option>
                    <option value="+229">🇧🇯 +229</option>
                    <option value="+267">🇧🇼 +267</option>
                    <option value="+226">🇧🇫 +226</option>
                    <option value="+257">🇧🇮 +257</option>
                    <option value="+238">🇨🇻 +238</option>
                    <option value="+237">🇨🇲 +237</option>
                    <option value="+236">🇨🇫 +236</option>
                    <option value="+235">🇹🇩 +235</option>
                    <option value="+269">🇰🇲 +269</option>
                    <option value="+243">🇨🇩 +243</option>
                    <option value="+253">🇩🇯 +253</option>
                    <option value="+20">🇪🇬 +20</option>
                    <option value="+240">🇬🇶 +240</option>
                    <option value="+291">🇪🇷 +291</option>
                    <option value="+251">🇪🇹 +251</option>
                    <option value="+241">🇬🇦 +241</option>
                    <option value="+220">🇬🇲 +220</option>
                    <option value="+233">🇬🇭 +233</option>
                    <option value="+224">🇬🇳 +224</option>
                    <option value="+245">🇬🇼 +245</option>
                    <option value="+225">🇨🇮 +225</option>
                    <option value="+254">🇰🇪 +254</option>
                    <option value="+266">🇱🇸 +266</option>
                    <option value="+231">🇱🇷 +231</option>
                    <option value="+218">🇱🇾 +218</option>
                    <option value="+261">🇲🇬 +261</option>
                    <option value="+265">🇲🇼 +265</option>
                    <option value="+223">🇲🇱 +223</option>
                    <option value="+222">🇲🇷 +222</option>
                    <option value="+230">🇲🇺 +230</option>
                    <option value="+212">🇲🇦 +212</option>
                    <option value="+258">🇲🇿 +258</option>
                    <option value="+264">🇳🇦 +264</option>
                    <option value="+227">🇳🇪 +227</option>
                    <option value="+234">🇳🇬 +234</option>
                    <option value="+242">🇨🇬 +242</option>
                    <option value="+250">🇷🇼 +250</option>
                    <option value="+239">🇸🇹 +239</option>
                    <option value="+221">🇸🇳 +221</option>
                    <option value="+248">🇸🇨 +248</option>
                    <option value="+232">🇸🇱 +232</option>
                    <option value="+252">🇸🇴 +252</option>
                    <option value="+27">🇿🇦 +27</option>
                    <option value="+211">🇸🇸 +211</option>
                    <option value="+249">🇸🇩 +249</option>
                    <option value="+255">🇹🇿 +255</option>
                    <option value="+228">🇹🇬 +228</option>
                    <option value="+216">🇹🇳 +216</option>
                    <option value="+256">🇺🇬 +256</option>
                    <option value="+260">🇿🇲 +260</option>
                    <option value="+263">🇿🇼 +263</option>
                  </select>
                </div>

                <div className="short-text-input">
                  <input
                    type="text"
                    name="phone"
                    placeholder="123 123 1234"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="phone-input"
                    required
                  />
                  {formData.phone && (
                    <button className="send-button" onClick={handleSendClick}>
                      <img src={sendIcon} alt="Send" />
                    </button>
                  )}
                </div>
              </div>
              {!isPhoneNumberValid && hasAttemptedSubmit && (
                <div className="error-message">
                  Please enter a valid phone number
                </div>
              )}
            </div>
          )}


{currentStep === 4 && (
  <div>
    <div className="question-label">What's your email?</div>
    <div className="short-text-input">
      <input
        className="input-field"
        type="email"
        name="email"
        placeholder="Enter your email"
        value={formData.email}
        onChange={handleInputChange}
        required
      />
      {formData.email && (
        <button className="send-button" onClick={handleSendClick}>
          <img src={sendIcon} alt="Send" />
        </button>
      )}
    </div>
    {validationMessages.email && (
      <p className="error-message">{validationMessages.email}</p>
    )}
  </div>
)}


{currentStep === 5 && (
            <div>
              <div className="question-label">
                How would you describe your creative work?
              </div>
              <div className="short-text-input">
                <input
                  className="input-field"
                  type="text"
                  name="creativeWork"
                  placeholder="What do you create?"
                  value={formData.creativeWork}
                  onChange={handleInputChange}
                  required
                />
                {formData.creativeWork && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.creativeWork && (
                <p className="error-message">{validationMessages.creativeWork}</p>
              )}
            </div>
          )}

          {currentStep === 6 && (
            <div>
              <div className="question-label">
                Do you share creative <br></br> work with teammates?
                {/* (e.g jpegs, pdfs, audio files, video files, links) */}
              </div>
              <div className="short-text-input">
                <select
                  name="sharingFrequency"
                  value={formData.sharingFrequency}
                  onChange={handleInputChange}
                  className="input-field"
                  required
                >
                  <option value="">Select frequency</option>
                  <option value="really often">
                    Yes, really often (+10 times a week)
                  </option>
                  <option value="somewhat often">
                    Yes, somewhat often (a few times a week)
                  </option>
                  <option value="rarely">
                    Yes but not that often (once a week or less)
                  </option>
                  <option value="Never">No, never (0 times a week)</option>
                </select>
                {formData.sharingFrequency && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.sharingFrequency && (
                <p className="error-message">{validationMessages.sharingFrequency}</p>
              )}
            </div>
          )}

          {currentStep === 7 && (
            <div>
              <div className="question-label">
                How many people <br></br> on your team?
              </div>
              <div className="short-text-input">
                <select
                  name="teamSize"
                  value={formData.teamSize}
                  onChange={handleInputChange}
                  className="input-field"
                  required
                >
                  <option value="">Select team size</option>
                  <option value="1">Just me</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10+">10+</option>
                </select>
                {formData.teamSize && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.teamSize && (
                <p className="error-message">{validationMessages.teamSize}</p>
              )}
            </div>
          )}

          {currentStep === 8 && (
            <div>
              <div className="question-label">
                How does your team communicate?
              </div>
              <div className="short-text-input">
                <select
                  name="mainMessenger"
                  value={formData.mainMessenger}
                  onChange={handleInputChange}
                  className="input-field"
                  required
                >
                  <option value="">Select a messenger</option>
                  <option value="iMessage">iMessage</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Slack">Slack</option>
                  <option value="Discord">Discord</option>
                  <option value="Telegram">Telegram</option>
                  <option value="Instagram">Instagram</option>
                </select>
                {formData.mainMessenger && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.mainMessenger && (
                <p className="error-message">
                  {validationMessages.mainMessenger}
                </p>
              )}
            </div>
          )}

          {currentStep === totalSteps && (
            <div>
              <div className="question-label">
                How much do you love using your messenger?
              </div>
              <div className="short-text-input">
                <select
                  name="mainMessengerFeelings"
                  placeholder="Select an answer"
                  value={formData.mainMessengerFeelings}
                  onChange={handleInputChange}
                  className="input-field"
                  required
                >
                  <option value=""> Select an answer</option>
                  <option value="I hate it">
                    <span>👎 </span>I hate it.
                  </option>
                  <option value="It does the job">🤷 It does the job.</option>
                  <option value="I love it">❤️ I love it.</option>
                </select>
                {formData.mainMessengerFeelings && (
                  <button className="send-button" onClick={handleSendClick}>
                    <img src={sendIcon} alt="Send" />
                  </button>
                )}
              </div>
              {validationMessages.mainMessengerFeelings && (
                <p className="error-message">
                  {validationMessages.mainMessengerFeelings}
                </p>
              )}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default WaitList;
